<template>
    <template v-if="checkTarefas">
        <div>
            <div class="container-geral">
                <!-- <div class="filter">
                    <label>Data de solicitação: </label>
                    <input
                        type="date"
                        autocorrect="off"
                        autocomplete="off"
                        placeholder="dd/mm/aaaa"
                        v-model="filtroData"
                    />
                    <button type="button" class="btn-valida" @click="dateFilter()">Filtrar</button>
                    <button type="button" class="btn-valida" @click="resetFilter()">Resetar</button>
                </div> -->

                <div class="page-title">
                    <h1>Histórico</h1>
                </div>
                <div class="empty-filter" v-if="emptyFilter">Este filtro está vazio!</div>
                <form action="">
                    <div class="table-form" v-for="(tarefa, keyTarefa) in tarefas" :key="keyTarefa">
                        <div class="container-titulo">
                            <div class="table-loja">{{ keyTarefa }}</div>
                        </div>
                        <table class="table" id="table" max_rows="3">
                            <thead class="column">
                                <th class="columns" v-for="texto in headers" :key="texto">{{ texto }}</th>
                            </thead>
                            <tbody class="row">
                                <tr class="full-rows" v-for="(produto, keyProduto) in tarefa" :key="keyProduto">
                                    <td class="rows">{{ produto.codigo_de_barras }}</td>
                                    <td class="rows">{{ produto.descricao }}</td>
                                    <td class="rows">{{ produto.preco_atual }}</td>
                                    <td class="rows">{{ convertDate(produto.data_validade) }}</td>
                                    <td class="rows">{{ convertDate(produto.data_solicitacao) }}</td>
                                    <td class="rows">{{ produto.quantidade }}</td>
                                    <td class="rows row-number">{{ produto.estoque }}</td>
                                    <td class="rows">{{ produto.preco_solicitado }}</td>
                                    <td
                                        class="rows"
                                        v-if="produto.status == 'Reprovado' && produto.justificativa != null"
                                    >
                                        <div
                                            class="justificativa-pedido"
                                            @click.prevent="displayJustify(produto.justificativa)"
                                        >
                                            {{ produto.status }}
                                        </div>
                                    </td>
                                    <td class="rows" v-else>{{ produto.status }}</td>
                                    <td class="rows" v-if="produto.status == 'Aprovado'">
                                        {{ produto.preco_comercial }}
                                    </td>
                                    <td class="rows row-date" v-if="produto.status == 'Aprovado'">
                                        {{ convertDate(produto.valido_ate) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                <transition name="fade">
                    <div v-if="showJustify" class="popUp">
                        <div class="tela-mensagem">
                            <div class="cancel-msg">
                                Justifique a recusa do pedido:
                                <div class="close-cancel" @click="(showJustify = false), (justify = '')">
                                    <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                                </div>
                            </div>
                            <div class="container-text">
                                <textarea
                                    class="text-justify"
                                    maxlength="100"
                                    name="justify"
                                    v-model="justify"
                                    id="justify"
                                    cols="50"
                                    rows="7"
                                    disabled
                                ></textarea>
                            </div>
                            <div class="cancel-btn">
                                <button @click="(showJustify = false), (justify = '')" type="button">Ok</button>
                            </div>
                        </div>
                    </div>
                </transition>

                <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
                <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
            </div>
        </div>
    </template>
    <template v-else-if="!checkTarefas">
        <div class="container-geral">
            <div class="empty-tarefas">Não há pedidos disponíveis.</div>
        </div>
    </template>
</template>

<script>
import img from '../../assets/img/broken-image.svg';
import MsgSucess from '../../components/MsgSucess';
import MsgError from '../../components/MsgError';
import HTTP from '../../http/http.js';
import moment from 'moment';

const TABLE_HEADERS = [
    'Código',
    'Descrição',
    'Preço Atual',
    'Validade',
    'Data de solicitação',
    'Quantidade',
    'Estoque ERP',
    'Preço Solicitado',
    'Status',
    'Preço Aprovado',
    'Valido Até',
];

export default {
    data() {
        return {
            tarefas: null,
            lojaFiltrada: 'all',
            emptyFilter: false,
            precoSolicitado: '',
            showJustify: false,
            justify: '',
            justifyIsInvalid: {
                mensagem: '',
                valida: false,
            },
            device: '',
            headers: [],
            showSucess: false,
            msgS: '',
            msgE: '',
            showAlert: false,
            precoSolicitadoIsInvalid: {
                valida: false,
            },
            precoMaior: false,
            filtroData: '',
        };
    },
    components: {
        MsgSucess,
        MsgError,
    },
    computed: {
        getHistoricoPedidos() {
            return this.$store.getters.getHistoricoPedidos;
        },
        getHistoricoCategoria() {
            return this.$store.getters.getHistoricoCategoria;
        },
    },
    created() {
        this.tarefas = this.getHistoricoCategoria;
        this.device = this.checkDevice();
        this.headers = TABLE_HEADERS;
    },

    methods: {
        checkDevice() {
            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                return 'tablet';
            } else if (
                /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                    ua
                )
            ) {
                return 'mobile';
            }
            return 'desktop';
        },

        dateFilter() {
            let resultado = {};
            if (this.filtroData != '') {
                const todosPedidos = this.getPedidosCategoria;

                Object.keys(todosPedidos).forEach((element) => {
                    let value = todosPedidos[element].filter((element) => {
                        return element.data_solicitacao == this.filtroData;
                    });
                    if (value != '') {
                        resultado[element] = value;
                    }
                });
            }
            this.tarefas = resultado;
            this.checkFilter(this.tarefas);
        },

        checkFilter(filter) {
            if (Object.keys(filter).length == '') {
                this.emptyFilter = true;
            } else {
                this.emptyFilter = false;
            }
        },

        resetFilter() {
            this.tarefas = this.getPedidosCategoria;
            this.filtroData = '';

            this.checkFilter(this.tarefas);
        },

        validaJustify() {
            this.justifyIsInvalid = this.justify == '' ? true : false;

            if (this.justifyIsInvalid) {
                this.justifyIsInvalid = {
                    mensagem: 'Informação Obrigatória',
                    valida: true,
                };
                return true;
            } else {
                this.justifyIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
                return false;
            }
        },

        isEmpty(target) {
            if (target == null || target == '') {
                return true;
            }
        },

        checkTarefas() {
            if (Object.keys(this.tarefas) > 0 || Object.keys(this.tarefas) != null) {
                return true;
            } else {
                return false;
            }
        },

        aceitaPedido() {
            let hasError = false;
            const data = { precos: [] };
            this.pedidosSelecionados.forEach((pedido) => {
                if (this.validaPrecoSolicitado(pedido) || this.validaDate(pedido)) {
                    hasError = true;
                    return;
                }
                if (hasError) return;
                let valido_ate = moment(pedido.valido_ate).format('YYYY-MM-DD');
                data.precos.push({
                    status: 'Aprovado',
                    validade: pedido.data_validade,
                    codigo_de_barras: pedido.codigo_de_barras,
                    loja: pedido.loja,
                    preco_solicitado: pedido.preco_solicitado,
                    valido_ate: valido_ate,
                    preco_venda_atual: pedido.preco_atual,
                });
            });
            if (hasError) return;

            const dataVuex = { pedidos: [] };

            this.pedidosSelecionados.forEach((pedido) => {
                let valido_ate = moment(pedido.valido_ate).format('YYYY-MM-DD');
                dataVuex.pedidos.push({
                    status: 'Aprovado',
                    data_validade: pedido.data_validade,
                    quantidade: pedido.quantidade,
                    codigo_de_barras: pedido.codigo_de_barras,
                    loja: pedido.loja,
                    preco_atual: pedido.preco_atual,
                    preco_solicitado: pedido.preco_solicitado,
                    valido_ate: valido_ate,
                    categoria_2: pedido.categoria_2,
                });
            });

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/admin/precos`, data)
                    .then(() => {
                        this.showSucess = true;
                        this.msgS = 'Pedido(s) aprovado com sucesso!';
                        this.$store.dispatch('setNovoHistorico', dataVuex);
                        this.removePedido(data.precos);

                        this.pedidosSelecionados = [];

                        const checkBoxes = this.$refs.check;
                        checkBoxes.forEach((checkbox) => {
                            checkbox.checked = false;
                        });

                        this.tarefas = this.getPedidosCategoria;

                        if (this.filtroData != '') {
                            this.dateFilter();
                        }

                        this.checkFilter(this.tarefas);

                        setTimeout(() => {
                            this.showSucess = false;
                        }, 1500);
                    })
                    .catch((erro) => {
                        console.error(erro);
                        this.showAlert = true;
                        this.msgE = 'Erro ao aprovar o produto, tente novamente.';

                        setTimeout(() => {
                            this.showAlert = false;
                            this.showSucess = false;
                        }, 1500);
                    });
            }
            // else {
            //     let precosSalvos = JSON.parse(localStorage.getItem('precos'));

            //     let dataToLS = null;

            //     if (precosSalvos) {
            //         precosSalvos.push(data);

            //         dataToLS = JSON.stringify(precosSalvos);
            //     } else {
            //         dataToLS = JSON.stringify([data]);
            //     }

            //     this.msgS = 'Seu pedido será atualizado ao sincronizar!';
            //     this.showSucess = true;

            //     localStorage.setItem('precosEdit', dataToLS);

            //     this.$store.dispatch('plusNumeroSync');

            //     setTimeout(() => {
            //         this.showSucess = false;
            //         this.$router.go(-1);
            //     }, 1500);
            // }
        },

        recusaPedido() {
            if (this.validaJustify()) return;
            if (this.pedidosSelecionados.length == 0) {
                this.showAlert = true;
                this.msgE = 'Nenhum pedido selecionado!';

                setTimeout(() => {
                    this.showAlert = false;
                }, 1500);

                return;
            }

            const data = { precos: [] };
            this.pedidosSelecionados.forEach((pedido) => {
                data.precos.push({
                    status: 'Reprovado',
                    validade: pedido.data_validade,
                    quantidade: pedido.quantidade,
                    codigo_de_barras: pedido.codigo_de_barras,
                    loja: pedido.loja,
                    preco_atual: pedido.preco_atual,
                    preco_solicitado: pedido.preco_solicitado,
                    valido_ate: pedido.valido_ate,
                    categoria_2: pedido.categoria_2,
                    justificativa: this.justify,
                    preco_venda_atual: pedido.preco_atual,
                });
            });

            const dataVuex = { pedidos: [] };

            this.pedidosSelecionados.forEach((pedido) => {
                dataVuex.pedidos.push({
                    status: 'Reprovado',
                    data_validade: pedido.data_validade,
                    quantidade: pedido.quantidade,
                    codigo_de_barras: pedido.codigo_de_barras,
                    loja: pedido.loja,
                    preco_atual: pedido.preco_atual,
                    preco_solicitado: pedido.preco_solicitado,
                    valido_ate: pedido.valido_ate,
                    categoria_2: pedido.categoria_2,
                    justificativa: this.justify,
                });
            });

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/admin/precos`, data)
                    .then(() => {
                        this.showJustify = false;
                        this.showSucess = true;
                        this.msgS = 'Pedido recusado com sucesso!';

                        this.$store.dispatch('setNovoHistorico', dataVuex);
                        this.removePedido(data.precos);

                        this.pedidosSelecionados = [];

                        this.tarefas = this.getPedidosCategoria;

                        if (this.filtroData != '') {
                            this.dateFilter();
                        }

                        this.checkFilter(this.tarefas);

                        const checkBoxes = this.$refs.check;
                        checkBoxes.forEach((checkbox) => {
                            checkbox.checked = false;
                        });

                        setTimeout(() => {
                            this.showSucess = false;
                        }, 1500);
                    })
                    .catch(() => {
                        this.showJustify = false;

                        this.showAlert = true;
                        this.msgE = 'Erro ao recusar o produto, tente novamente.';

                        setTimeout(() => {
                            this.showAlert = false;
                        }, 1500);
                    });
            }
            // else {
            //     let precosSalvos = JSON.parse(localStorage.getItem('precos'));

            //     let dataToLS = null;

            //     if (precosSalvos) {
            //         precosSalvos.push(data);

            //         dataToLS = JSON.stringify(precosSalvos);
            //     } else {
            //         dataToLS = JSON.stringify([data]);
            //     }

            //     this.msgS = 'Seu pedido será atualizado ao sincronizar!';
            //     this.showSucess = true;

            //     localStorage.setItem('precosEdit', dataToLS);

            //     this.$store.dispatch('plusNumeroSync');

            //     setTimeout(() => {
            //         this.showSucess = false;
            //         this.$router.go(-1);
            //     }, 1500);
            // }
        },

        displayJustify(value) {
            this.justify = value;
            this.showJustify = true;
        },

        removePedido(pedidos) {
            pedidos.forEach((pedido) => {
                let codigo = pedido.codigo_de_barras;
                let validade = pedido.validade;
                let loja = pedido.loja;

                const itemToBeRemoved = { codigo, validade, loja };

                this.$store.dispatch('deletePedido', itemToBeRemoved);
            });
        },

        validaDate(target) {
            if (target.valido_ate == null) {
                this.showAlert = true;
                this.msgE = 'Preencha todas as datas necessárias.';
                return true;
            } else if (target.valido_ate >= target.data_validade || target.valido_ate < this.dataAtual()) {
                this.showAlert = true;
                this.msgE = 'Data inválida.';
                return true;
            } else {
                return false;
            }
        },

        validaPrecoSolicitado(preco) {
            if (preco.preco_atual != null) {
                this.precoSolicitadoIsInvalid =
                    preco.preco_solicitado == '' || preco.preco_solicitado <= 0 ? true : false;
                this.precoMaior = preco.preco_solicitado > preco.preco_atual ? true : false;
            } else {
                this.precoSolicitadoIsInvalid =
                    preco.preco_solicitado == '' || preco.preco_solicitado <= 0 ? true : false;
            }

            var input = document.querySelector('#inputPrecoSolicitado');
            input.addEventListener('keypress', function (e) {
                if (!checkChar(e)) {
                    e.preventDefault();
                }
            });
            function checkChar(e) {
                var char = String.fromCharCode(e.keyCode);

                var pattern = '[0-9,.]';
                if (char.match(pattern)) {
                    return true;
                }
            }

            if (this.precoSolicitadoIsInvalid) {
                this.showAlert = true;
                this.msgE = 'Preencha todos os preços necessários.';
                return true;
            } else if (preco.preco_solicitado > preco.preco_atual) {
                this.showAlert = true;
                this.msgE = 'O preço sugerido não pode ser maior que o preço atual.';

                return true;
            } else {
                return false;
            }
        },

        convertDate(date) {
            if (date != '') {
                let dateSplit = date.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        dataAtual() {
            let momentData = moment();
            let minDate = '';
            if (momentData.hour() < 13) {
                minDate = momentData.add(1, 'd');
            } else {
                minDate = momentData.add(2, 'd');
            }
            let dataHoje = minDate.format('YYYY-MM-DD');
            return dataHoje;
        },

        decrementPreco(value) {
            this.precoSolicitado = value;
            this.precoSolicitado--;
            if (this.precoSolicitado < 0) {
                this.precoSolicitado = 0;
            }
        },

        incrementPreco(value) {
            // if (value > 99999) {
            //     value = 99999;
            // }
            return ++value;
        },

        closeError() {
            this.showAlert = false;
        },
    },
};
</script>

<style scoped>
.container-geral {
    margin: 86px 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.page-title {
    position: fixed;
    top: 56px;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.page-title h1 {
    font-size: 25px;
    font-weight: bold;
}

.filter {
    max-width: 50%;
    background-color: var(--azul-escuro);
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.filter label {
    font-size: 22px;
}

.filter input {
    font-size: 18px !important;
    height: 30px !important;
}

.empty-tarefas {
    font-size: 25px;
    color: #ef6969;
}

.table-form {
    overflow-x: auto;
    margin-top: 20px;
    overflow-y: auto;
    max-height: 230px;
    display: flex;
    flex-direction: column;
}

.container-titulo {
    background-color: #fff;
    position: sticky;
    left: 0px;
    top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    z-index: 1;
}

.table-loja {
    border: none;
    box-shadow: 0 0 8px #adadad;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
    width: fit-content;
    padding: 3px 7px;
}

table,
td,
th {
    border-collapse: collapse;
    margin-bottom: 20px;
}

td,
th {
    padding: 0.3rem;
}

.column {
    gap: 15px;
    border: 1px solid #fff;
    background-color: #f0efef;
}

.columns {
    border: 1px solid #fff;
    font-size: 14px;
    min-width: 70px;
}

.row {
    border: 1px solid #fff;
    border-radius: 10%;
    overflow-y: auto;
}

.full-rows {
    margin-bottom: 1rem;
}

tr:nth-child(even) {
    background-color: var(--verde);
}

tr:nth-child(odd),
tr:nth-child(odd) a {
    background-color: #d0d0d0;
    color: #000;
}

.rows {
    font-size: 13px;
    text-align: center;
    border: 1px solid #fff;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 20ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.justificativa-pedido {
    font-size: 13px;
    text-decoration: underline;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 20ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.justificativa-pedido:hover {
    cursor: pointer;
}

.row-date {
    max-width: 23ch;
}

.row-number {
    max-width: 10ch;
}

input {
    height: 22px;
}

input[type='number'] {
    max-width: 6rem;
    font-size: 14px;
    text-align: center;
    border: 1px solid var(--azul-escuro);
    border-radius: 5px;
    outline-color: var(--verde);
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type='date'] {
    max-width: 15rem;
    font-size: 14px;
    text-align: center;
    border: 1px solid var(--azul-escuro);
    border-radius: 5px;
    outline-color: var(--verde);
    background-color: #fff;
}

.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
}

.controle button img {
    width: 14px;
}

.btn-valida {
    padding: 10px;
    background-color: var(--verde);
    border: 1px solid var(--azul-escuro);
    border-radius: 10px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

.btn-valida:hover {
    background-color: var(--azul-escuro);
    border: 1px solid var(--verde);
}

.container-check {
    width: 100%;
    height: 35px;
    display: flex;
    place-items: center;
    justify-content: center;
    border: 1px solid #fff;
}

.row-link {
    font-size: 14px;
    text-decoration: underline;
    color: var(--azul-escuro);
}

.numbers {
    font-size: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ef6969;
    color: #ef6969;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
}

.filters {
    border-radius: 10px;
    padding: 15px 10px;
    background-color: var(--verde);
    margin-left: 15%;
    margin-right: 15%;
}

.filters h1 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.filtro {
    display: flex;
    place-items: center;
    gap: 10px;
}

.filtro-select {
    width: 200px;
    padding: 8px;
    background-color: #ececec;
    letter-spacing: 0.5px;
    background-color: var(--azul-escuro);
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
}

.empty-filter {
    padding: 20px 0px;
    color: var(--vermelho);
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 19%;
    margin-right: 19%;
}

.label-filter {
    font-size: 1.1rem;
    color: #fff;
}

.mensagemErro {
    color: var(--vermelho);
    font-size: 1rem;
    margin-top: 5px;
}

.erro-justifique {
    margin-left: 5%;
}

.corredores {
    padding: 10px 12px;
    margin: 20px 13.5% 0px;
    border-radius: 10px;
    background-color: var(--azul-escuro);
    border-bottom: 1px solid var(--azul-escuro);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 0;
    box-shadow: 4px 6px 7px #858585;
}

.container-tarefa {
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 0px 15%;
    border: 2px solid var(--azul-escuro);
    border-radius: 10px;
    margin-top: -10px;
    background-color: #fff;
    padding: 50px;
}

.corredores-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    max-width: 25ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.produto1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 30px 10px;
    /* background-color: var(--verde); */
    margin-bottom: 20px;
    border: 2px solid var(--azul-escuro);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
}

.produto1:hover {
    -webkit-animation: shadow-pop-br 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    animation: shadow-pop-br 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    background-color: #f4f4f4;
    /* transition: all 0.1s; */
}

.btn-edit {
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding: 15px;
    border: 1px solid #fff;
    display: flex;
    border-radius: 50px;
    background-color: #e4e4e4;
    cursor: pointer;
}

.btn-edit img {
    width: 30px;
    height: 30px;
}

.btn-edit:hover {
    animation: is-rotating 1s infinite;
    box-shadow: none;
}

.container-img {
    display: flex;
    place-items: center;
    place-content: center;
}

.container-img img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.nome-validade {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.nome-validade h2 {
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 35ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--azul-escuro);
}

.validade-tarefa {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--azul-escuro);
}

.pedido-status {
    color: var(--verde);
}

h2.produto-alerta {
    color: #ef6969;
    font-weight: bold;
}

.title-retirada {
    color: #fff;
}

.validade-retirada {
    color: #ef6969;
}

.empty-class {
    border: 1px solid #ef6969 !important;
}

@media (max-width: 319px) {
    .corredores-title {
        font-size: 14px;
        max-width: 24ch;
    }

    .nome-validade h2 {
        max-width: 17ch;
    }
}

@media (max-width: 420px) {
    .produto1 {
        padding: 20px 10px;
        margin-left: 0;
        margin-right: 0;
    }

    .btn-edit {
        padding: 8px;
    }

    .btn-edit img {
        width: 25px;
        height: 25px;
    }

    .validade-tarefa {
        font-size: 14px;
    }
}

@media (max-width: 1025px) {
    .columns {
        font-size: 12px;
    }

    .rows {
        font-size: 12px;
    }

    input[type='number'] {
        font-size: 14px;
    }

    input[type='date'] {
        font-size: 14px;
    }
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

/* Tela Warning de cancelamento */

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 60%);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 20%;
    top: 10%;
    right: 20%;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 17px;
}

.tela-mensagem img {
    width: 200px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel img {
    width: 24px;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.container-text {
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    justify-content: center;
}

.text-justify {
    border-radius: 5px;
    outline-color: var(--verde);
}

.cancel-btn {
    margin-top: 15px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 2px;
    background-color: var(--verde);
    color: #fff;
}

@-webkit-keyframes shadow-pop-br {
    0% {
        -webkit-box-shadow: 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro);
        box-shadow: 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro);
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }
    100% {
        -webkit-box-shadow: 1px 1px var(--azul-escuro), 2px 2px var(--azul-escuro), 3px 3px var(--azul-escuro),
            4px 4px var(--azul-escuro), 5px 5px var(--azul-escuro), 6px 6px var(--azul-escuro),
            7px 7px var(--azul-escuro), 8px 8px var(--azul-escuro);
        box-shadow: 1px 1px var(--azul-escuro), 2px 2px var(--azul-escuro), 3px 3px var(--azul-escuro),
            4px 4px var(--azul-escuro), 5px 5px var(--azul-escuro), 6px 6px var(--azul-escuro),
            7px 7px var(--azul-escuro), 8px 8px var(--azul-escuro);
        -webkit-transform: translateX(-8px) translateY(-8px);
        transform: translateX(-8px) translateY(-8px);
    }
}
@keyframes shadow-pop-br {
    0% {
        -webkit-box-shadow: 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro);
        box-shadow: 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro),
            0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro), 0 0 var(--azul-escuro);
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }
    100% {
        -webkit-box-shadow: 1px 1px var(--azul-escuro), 2px 2px var(--azul-escuro), 3px 3px var(--azul-escuro),
            4px 4px var(--azul-escuro), 5px 5px var(--azul-escuro), 6px 6px var(--azul-escuro),
            7px 7px var(--azul-escuro), 8px 8px var(--azul-escuro);
        box-shadow: 1px 1px var(--azul-escuro), 2px 2px var(--azul-escuro), 3px 3px var(--azul-escuro),
            4px 4px var(--azul-escuro), 5px 5px var(--azul-escuro), 6px 6px var(--azul-escuro),
            7px 7px var(--azul-escuro), 8px 8px var(--azul-escuro);
        -webkit-transform: translateX(-8px) translateY(-8px);
        transform: translateX(-8px) translateY(-8px);
    }
}
</style>
